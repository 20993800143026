import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Footer from '../Footer/Footer';  // Component for Footer




export default function AboutUsPage() {
  return (
    <Container maxWidth="lg">
      
    </Container>
  );
}
